import styles from './Dashboard.module.scss'

import NaturalGasProductionByCompany from './components/NaturalGasProductionByCompany'
import NaturalGasProductionByDeposit from './components/NaturalGasProductionByDeposit'
import NaturalGasProductionByOwner from './components/NaturalGasProductionByDepositOwner'
import NaturalGasProductionByProductionLicenseOwner from './components/NaturalGasProductionByProductionLicenseOwner'

export default function Dashboard() {
	return (
		<div className={styles.dashboard_wrapper}>
			<NaturalGasProductionByDeposit />
			<NaturalGasProductionByCompany />
			<NaturalGasProductionByProductionLicenseOwner />
			<NaturalGasProductionByOwner />
		</div>
	)
}
