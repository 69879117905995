import { createBrowserRouter, Navigate, Outlet, RouterProvider, useRouteError } from "react-router-dom";
import App from "../App";
import Fuentes from "../pages/fuentes/Fuentes";
import Informes from "../pages/informes/Informes";
import Trabajos from "../pages/trabajos/Trabajos";
import TrabajosDetalle from "../pages/trabajos/TrabajosDetalle";
import Dashboard from "../pages/dashboard/Dashboard";
import Deposits from "../pages/deposits/Deposits";
import Companies from "../pages/companies/Companies";
import ProductionLicenses from "../pages/production-licenses/ProductionLicenses";

const Error = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const error: any = useRouteError()

	return (
		<App>
			<div>{error?.message ?? 'Error desconocido'}</div>
		</App>
	)
}

const MainLayout = () => {
	return (
		<App>
			<Outlet />
		</App>
	)
}

const router = createBrowserRouter([
	{
		path: "/",
		element: <MainLayout />,
		children: [
			{
				path: `/`,
				index: true,
				element: (
					<Navigate to="/dashboard" replace />
				),
			},
			{
				path: `/dashboard`,
				element: (
					<Dashboard />
				),
			},
			{
				path: `/fuentes`,
				element: (
					<Fuentes />
				),
			},
			{
				path: `/informes`,
				element: (
					<Informes />
				),
			},
			{
				path: '/trabajos',
				children: [
					{ index: true, element: <Trabajos /> },
					{ path: ':id', element: <TrabajosDetalle /> },
				]
			},
			{
				path: `/deposits`,
				element: (
					<Deposits />
				),
			},
			{
				path: `/production-licenses`,
				element: (
					<ProductionLicenses />
				),
			},
			{
				path: `/companies`,
				element: (
					<Companies />
				),
			},
		],
		errorElement: <Error />,
	},
]);

function Routes() {
	return <RouterProvider router={router} />
}

export default Routes